import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../components/header/header";
import "./dienstleistungen.scss";

const Dienstleistungen = () => {
    const { t, i18n } = useTranslation();
    const accentColor = "250, 186, 0";

    const [dienstleistungen, setDienstleistungen] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch für Dienstleistungen
                const dienstleistungenRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/dienstleistungen`);
                if (dienstleistungenRes.data && dienstleistungenRes.data.length > 0) {
                    setDienstleistungen(dienstleistungenRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [lang]);

    usePageName(t("site.dienstleistungen") + " | " + t("site.pageName"));

    return (
        <div className="dienstleistungen">
            <Header title={t("site.dienstleistungen")} image="https://studiog.2ix.de/wp-content/uploads/2024/10/studiog_beschneiungstechnik_2.jpg" accent={accentColor} />
            <div className="container">
                <div className="grid">
                    {isLoading ? <div className='loading' /> : dienstleistungen.map(item => (
                        <Link key={item.id} className="item" to={`/${i18n.language}/dienstleistungen/${item.slug}`}>
                            <div className="left">
                                <img src={item.acf.image} alt={item.title.rendered} />
                            </div>
                            <div className="right">
                                <h2>{item.title.rendered}</h2>
                                <h4>{item.acf.subheading}</h4>
                                <div className="wp-content" dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }} />
                                <Link to={`/${i18n.language}/dienstleistungen/${item.slug}`} className="button">{t("btn.weitereInfos")}</Link>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dienstleistungen;
