import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../navbar/navbar.scss"

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const [isScroll, setIsScroll] = useState(false);
    const [isActiveLanguage, setIsActiveLanguage] = useState(false)

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const changeLanguage = (lng) => {
        const { pathname, search, hash } = window.location;

        const currentLang = pathname.split('/')[1];
        const newPathname = pathname.replace(`/${currentLang}/`, `/${lng}/`);

        navigate(newPathname + search + hash);
        i18n.changeLanguage(lng);

        setIsActive(false);
        setIsActiveLanguage(false)
    };

    const handleClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
    };

    const handleClickLanguage = event => {
        // 👇️ toggle isActive state on click
        setIsActiveLanguage(current => !current);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 1) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="nav">
            <div className={isActive ? "navbar active" : isScroll ? "navbar scroll" : "navbar"}>
                <div className="container">
                    <div className="left">
                        <div className={isActive ? "openNav active" : "openNav"} onClick={handleClick}>
                            <div className="navLines"></div>
                            <div className="navLines"></div>
                        </div>
                    </div>
                    <Link to={`/${i18n.language}/`} className="logo">
                        <img src="/assets/logoGray.svg" alt="Logo" />
                    </Link>
                </div>
            </div>
            <div className={isActive ? "navModal open" : "navModal"}>
                <div className="left">
                    <div className="languageBtn" onClick={handleClickLanguage}>
                        <span className="selectedLanguage">{i18n.language}</span>
                        {isActiveLanguage &&
                            <div className="selectLanguage">
                                {i18n.language !== 'it' && <span
                                    onClick={() => changeLanguage('it')}
                                    className={i18n.language === 'it' ? 'active' : ''}
                                >it</span>}
                                {i18n.language !== 'de' && <span
                                    onClick={() => changeLanguage('de')}
                                    className={i18n.language === 'de' ? 'active' : ''}
                                >de</span>}
                                {i18n.language !== 'en' && <span
                                    onClick={() => changeLanguage('en')}
                                    className={i18n.language === 'en' ? 'active' : ''}
                                >en</span>}
                            </div>
                        }
                    </div>
                </div>
                <div className="right">
                    <div className="mainLinks">
                        <NavLink to={`/${i18n.language}/`} onClick={handleClick}>
                            <h2>{t("site.home")}</h2>
                        </NavLink>
                        <NavLink to={`/${i18n.language}/news`} onClick={handleClick}>
                            <h2>{t("site.news")}</h2>
                        </NavLink>
                        <NavLink to={`/${i18n.language}/dienstleistungen`} onClick={handleClick}>
                            <h2>{t("site.dienstleistungen")}</h2>
                        </NavLink>
                        <NavLink to={`/${i18n.language}/team`} onClick={handleClick}>
                            <h2>{t("site.team")}</h2>
                        </NavLink>
                        <NavLink to={`/${i18n.language}/kontakt`} onClick={handleClick}>
                            <h2>{t("site.kontakt")}</h2>
                        </NavLink>
                    </div>
                    <div className="subLinks">
                        <div className="top">
                            <NavLink to={`/${i18n.language}/downloads`} onClick={handleClick}>
                                <h3>{t("site.downloads")}</h3>
                            </NavLink>
                            <NavLink to={`/${i18n.language}/jobs`} onClick={handleClick}>
                                <h3>{t("site.jobs")}</h3>
                            </NavLink>
                            <NavLink to={`/${i18n.language}/impressum`} onClick={handleClick}>
                                <h3>{t("site.impressum")}</h3>
                            </NavLink>
                            <NavLink to={`/${i18n.language}/datenschutz`} onClick={handleClick}>
                                <h3>{t("site.datenschutz")}</h3>
                            </NavLink>
                        </div>
                        <div className="bottom">
                            <h3>© {new Date().getFullYear()} {t("site.pageName")}</h3>
                            <Link to="tel:+390474411324">
                                <h4>+39 0474 411 324</h4>
                            </Link>
                            <Link to="mailto:info@studiog.it">
                                <h4>info@studiog.it</h4>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default Navbar