import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { usePageName } from "react-page-name";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Header from "../../components/header/header";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import "./downloads.scss";

const Downloads = () => {
    const { t, i18n } = useTranslation();
    const accentColor = "226, 0, 26";

    usePageName(t("site.downloads") + " | " + t("site.pageName"));

    const [downloads, setDownloads] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(
                    `https://studiog.2ix.de/wp-json/wp/v2/downloads`
                );
                setDownloads(res.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllRef();
    }, [lang]);

    // Extract unique categories from downloads
    const uniqueCategories = Array.from(
        new Set(downloads?.map((download) => download.acf.category))
    );

    return (
        <div className="downloads">
            <Header title={t("site.downloads")} image="https://studiog.2ix.de/wp-content/uploads/2024/10/studiog_team.jpg" accent={accentColor} />
            <div className="container">
                {uniqueCategories.map((category) => (
                    <div className="group" key={category}>
                        <h4>{t("site.downloads")}</h4>
                        <h2>{category}</h2>
                        {isLoading ? (
                            <div className="loading" />
                        ) : (
                            downloads
                                .filter((download) => download.acf.category === category)
                                .map((filteredDownload) => (
                                    <Link
                                        to={filteredDownload.acf.datei}
                                        key={filteredDownload.id}
                                        target="_blank"
                                        className="listLink"
                                    >
                                        <InsertDriveFileOutlinedIcon />
                                        <span className="left">{filteredDownload.acf.datatype}</span>
                                        <span className="right">{filteredDownload.title.rendered}</span>
                                    </Link>
                                ))
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Downloads;
