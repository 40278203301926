import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import axios from "axios";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Header from "../../components/header/header";
import "./news.scss";

const News = () => {
    const { t, i18n } = useTranslation();
    const accentColor = "250, 186, 0";

    const [news, setNews] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch für News
                const newsRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/news`);
                if (newsRes.data && newsRes.data.length > 0) {
                    setNews(newsRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [lang]);

    usePageName(t("site.news") + " | " + t("site.pageName"));

    return (
        <div className="news">
            <Header title={t("site.news")} image="https://studiog.2ix.de/wp-content/uploads/2024/10/studiog_beschneiungstechnik_2.jpg" accent={accentColor} />
            <div className="container">
                <div className="grid">
                    {isLoading ? <div className='loading' /> : news.map(item => (
                        <Link key={item.id} className="item" to={`/${i18n.language}/news/${item.slug}`}>
                            <div className="box">
                                <div className="top">
                                    <span className="name">
                                        {item.acf.titel} {item.title.rendered}
                                    </span>
                                    <ArrowForwardOutlinedIcon />
                                </div>
                                <div className="bottom">
                                    <span>{t("btn.zumProjekt")}</span>
                                </div>
                            </div>
                            <img
                                className="image"
                                src={item.acf.image || "/assets/placeholder.png"}
                                alt={item.title.rendered}
                            />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default News;
