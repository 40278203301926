import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

import Home from "./pages/home/home";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import NotFound from "./pages/notfound/notfound";
import Template from "./pages/template/template";
import Kontakt from "./pages/kontakt/kontakt";
import Downloads from "./pages/downloads/downloads";
import Team from "./pages/team/team";
import ViewTeam from "./pages/team/viewTeam/viewTeam";
import Dienstleistungen from "./pages/dienstleistungen/dienstleistungen";
import ViewDienstleistungen from "./pages/dienstleistungen/viewDienstleistungen/viewDienstleistungen";
import News from "./pages/news/news";
import ViewNews from "./pages/news/viewNews/viewNews";
import Maintenance from "./pages/maintenance/maintenance";

import translationDE from "./translations/de.json";
import translationIT from "./translations/it.json";
import translationEN from "./translations/en.json";

const isMaintenanceMode = false;
const lang = window.location.pathname.substring(1, 3);

i18n.init({
  interpolation: { escapeValue: false },
  lng: lang,
  resources: {
    de: {
      translation: translationDE,
    },
    it: {
      translation: translationIT,
    },
    en: {
      translation: translationEN,
    },
  },
});

function App() {

  const Layout = () => {
    return (
      <div className="site">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    )
  }

  const router = createBrowserRouter([
    {
      path: "/:lang",
      element: isMaintenanceMode ? <Maintenance /> : <Layout />,
      children: isMaintenanceMode ? [] : [
        {
          path: "/:lang",
          element: <Home />,
        },
        {
          path: "/:lang/team",
          element: <Team />,
        },
        {
          path: "/:lang/team/:id",
          element: <ViewTeam />,
        },
        {
          path: "/:lang/dienstleistungen",
          element: <Dienstleistungen />,
        },
        {
          path: "/:lang/dienstleistungen/:id",
          element: <ViewDienstleistungen />,
        },
        {
          path: "/:lang/news",
          element: <News />,
        },
        {
          path: "/:lang/news/:id",
          element: <ViewNews />,
        },
        {
          path: "/:lang/kontakt",
          element: <Kontakt />,
        },
        {
          path: "/:lang/downloads",
          element: <Downloads />,
        },
        {
          path: "/:lang/datenschutz",
          element: <Template />,
        },
        {
          path: "/:lang/impressum",
          element: <Template />,
        },
      ]
    },
    {
      path: "*",
      element: isMaintenanceMode ? <Maintenance /> : <NotFound />,
    },
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  );
}

export default App;
