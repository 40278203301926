import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import Header from "../../components/header/header";
import "./kontakt.scss";

const Kontakt = () => {
    const { t } = useTranslation();
    const accentColor = "90, 157, 64";

    usePageName(t("site.kontakt") + " | " + t("site.pageName"));

    return (
        <div className="kontakt">
            <Header title={t("site.kontakt")} image="https://studiog.2ix.de/wp-content/uploads/2024/10/studiog_team.jpg" accent={accentColor} />
            <div className="container">
                <div className="group">
                    <h4>{t("site.kontakt")}</h4>
                    <h2>{t("kontakt.kontaktierenSieUns")}</h2>
                    <Link to="tel:+390474411324" className="listLink">
                        <LocalPhoneOutlinedIcon />
                        <span className="content">+39 0474 411 324</span>
                        <span className="label">{t("kontakt.telefon")}</span>
                    </Link>
                    <Link to="mailto:info@studiog.it" className="listLink">
                        <EmailOutlinedIcon />
                        <span className="content">info@studiog.it</span>
                        <span className="label">{t("kontakt.email")}</span>
                    </Link>
                    <Link to="mailto:studiog@arubapec.it" className="listLink">
                        <MarkEmailReadOutlinedIcon />
                        <span className="content">studiog@arubapec.it</span>
                        <span className="label">PEC</span>
                    </Link>
                </div>
                <div className="group">
                    <h4>{t("site.kontakt")}</h4>
                    <h2>{t("kontakt.anfahrt")}</h2>
                    <Link to="https://maps.app.goo.gl/g8tnqGdTvsW8CXbc6" className="listLink" target="blank">
                        <NearMeOutlinedIcon />
                        <span className="content">Rienzfeldstraße 30, 39031 Bruneck, Südtirol</span>
                        <span className="label">{t("kontakt.adresse")}</span>
                    </Link>
                </div>
                <div className="group">
                    <h4>{t("site.kontakt")}</h4>
                    <h2>{t("kontakt.rechnungsDaten")}</h2>
                    <Link className="listLink">
                        <AccountBalanceOutlinedIcon />
                        <span className="content">02524420219</span>
                        <span className="label">{t("kontakt.mwstNr")}</span>
                    </Link>
                    <Link className="listLink">
                        <AccountBalanceOutlinedIcon />
                        <span className="content">02524420219</span>
                        <span className="label">{t("kontakt.steuernummer")}</span>
                    </Link>
                    <Link className="listLink">
                        <QrCode2OutlinedIcon />
                        <span className="content">USAL8PV</span>
                        <span className="label">{t("kontakt.empfängerkodex")}</span>
                    </Link>
                    <Link className="listLink">
                        <EuroOutlinedIcon />
                        <span className="content">000300021326</span>
                        <span className="label">{t("kontakt.kontonummer")}</span>
                    </Link>
                    <Link className="listLink">
                        <HorizontalRuleOutlinedIcon />
                        <span className="content">IT 14 I 08035 58242 000300021326</span>
                        <span className="label">IBAN</span>
                    </Link>
                    <Link className="listLink">
                        <HorizontalRuleOutlinedIcon />
                        <span className="content">RZSBIT21005</span>
                        <span className="label">SWIFT/BIC</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Kontakt;
