import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import axios from "axios";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Header from "../../components/header/header";
import "./team.scss";

const Team = () => {
    const { t, i18n } = useTranslation();

    const [team, setTeam] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch für Team
                const teamRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/team?per_page=100`);
                if (teamRes.data && teamRes.data.length > 0) {
                    const sortedTeam = teamRes.data.sort((a, b) => {
                        const groupA = parseInt(a.acf.group);
                        const groupB = parseInt(b.acf.group);
                        if (groupA !== groupB) {
                            return groupA - groupB;
                        }
                        return new Date(a.date).getTime() - new Date(b.date).getTime();
                    });
                    setTeam(sortedTeam);
                }
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        };

        fetchData();
    }, [lang]);

    // Extract unique categories from team
    const uniqueCategories = Array.from(
        new Set(team?.map((teamMember) => teamMember.acf.group))
    );

    usePageName(t("site.team") + " | " + t("site.pageName"));

    return (
        <div className="team">
            <Header title={t("site.team")} image="https://studiog.2ix.de/wp-content/uploads/2024/10/studiog_team.jpg" />
            <div className="container">
                {uniqueCategories.map((group) => (
                    <div className="group" key={group}>
                        <div className="grid">
                            {isLoading ? <div className='loading' /> :
                                team
                                    .filter((member) => member.acf.group === group)
                                    .map(item => (
                                        <Link key={item.id} className="item" to={`/${i18n.language}/team/${item.slug}`}>
                                            <div className="box" style={{ background: `rgba(${item.acf.bereich}, 0.7)` }}>
                                                <div className="top">
                                                    <span className="name">
                                                        {item.acf.titel} {item.title.rendered}
                                                    </span>
                                                    <ArrowForwardOutlinedIcon />
                                                </div>
                                                <div className="bottom">
                                                    <span>{item.acf.funktion}</span>
                                                </div>
                                            </div>
                                            <img
                                                className="image"
                                                src={item.acf.image || "/assets/placeholder.png"}
                                                alt={item.title.rendered}
                                            />
                                        </Link>
                                    ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
