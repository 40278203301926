import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import Header from "../../../components/header/header";
import axios from "axios";
import "./viewNews.scss";

const ViewNews = () => {
    const { t, i18n } = useTranslation();
    const accentColor = "250, 186, 0";

    const [news, setNews] = useState(null); // Initialize as null
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    const location = useLocation();
    const newsSlug = location.pathname.split("/")[3];

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für News
                const newsRes = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/news?slug=${newsSlug}`);
                if (newsRes.data && newsRes.data.length > 0) {
                    setNews(newsRes.data[0]); // Set to first item if array is returned
                } else {
                    setNews(null); // Set to null if no data
                }
            } catch (err) {
                console.log(err);
                setNews(null); // Set to null in case of error
            } finally {
                setLoading(false); // Ensure loading is stopped in all cases
            }
        };

        fetchAllRef();
    }, [lang, newsSlug]);

    usePageName(isLoading ? `${t("site.loading")} | ${t("site.pageName")}` : `${news.title.rendered || ''} | ${t("site.pageName")}`);

    return (
        <div className="viewNews">
            {isLoading ? (
                <div className="loading" />
            ) : (
                <div className="content">
                    <Header title={news.title.rendered} image={news.acf.image} accent={accentColor} />
                    <div className="container">
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: news.content.rendered }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewNews;
