import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePageName } from "react-page-name";
import axios from "axios";
import "./template.scss"
import Header from "../../components/header/header";

const Template = () => {
    const { t, i18n } = useTranslation();
    const accentColor = "90, 157, 64";

    const location = useLocation();
    const [data, setPost] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    const parts = location.pathname.split("/").filter(Boolean);
    const post = parts[parts.length - 1]

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                const res = await axios.get(`https://studiog.2ix.de/wp-json/wp/v2/posts?slug=${post}`)
                setPost(res.data[0]);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [post, lang])

    usePageName(isLoading ? `Laden | ` + t("site.pageName") : `${data.title.rendered} | ` + t("site.pageName"));

    return (
        <div className="template">
            <Header title={!isLoading && data.title.rendered} image={!isLoading && data.acf.image} accent={accentColor} />
            <div className="container">
                {isLoading
                    ? <div className='loading' />
                    : <div className='content'>
                        <div className="wp-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />
                    </div>
                }
            </div>
        </div>
    )
};

export default Template