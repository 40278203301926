import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import "../footer/footer.scss"


const Footer = () => {

    const { t, i18n } = useTranslation();

    return (
        <footer className="footer">
            <div className="top">
                <Link to={`/${i18n.language}/downloads`}>
                    <h3>{t("site.downloads")}</h3>
                    <ArrowForwardIosOutlinedIcon />
                </Link>
                <Link to={`/${i18n.language}/jobs`}>
                    <h3>{t("site.jobs")}</h3>
                    <ArrowForwardIosOutlinedIcon />
                </Link>
                <Link to={`/${i18n.language}/datenschutz`}>
                    <h3>{t("site.datenschutz")}</h3>
                    <ArrowForwardIosOutlinedIcon />
                </Link>
                <Link to={`/${i18n.language}/impressum`}>
                    <h3>{t("site.impressum")}</h3>
                    <ArrowForwardIosOutlinedIcon />
                </Link>
            </div>
            <div className="bottom">
                <div className="left">
                    <span>© {new Date().getFullYear()} {t("site.pageName")} <b>|</b> <Link to="tel:+390474411324">+39 0474 411 324</Link> <b>|</b> <Link to="mailto:info@studiog.it">info@studiog.it</Link></span>
                </div>
                <img src="/assets/logoWhite.svg" alt="Logo" />
            </div>
        </footer>
    )
};

export default Footer